export const menuRoles = {
    itensMenu: {
        cadastroDeAtores: {
            roles: [
                'CADASTRODEATORES_ATORES_DELETAR',
                'CADASTRODEATORES_ATORES_VISUALIZAR',
                'CADASTRODEATORES_ATORES_SALVAR'
            ],
            atores: [
                'CADASTRODEATORES_ATORES_DELETAR',
                'CADASTRODEATORES_ATORES_VISUALIZAR',
                'CADASTRODEATORES_ATORES_SALVAR'
            ]
        },
        caracteristicas: {
            roles: [
                'CARACTERISTICAS_ARTES-MARCIAIS_DELETAR',
                'CARACTERISTICAS_ARTES-MARCIAIS_VISUALIZAR',
                'CARACTERISTICAS_ARTES-MARCIAIS_SALVAR',
                'CARACTERISTICAS_BELEZA_DELETAR',
                'CARACTERISTICAS_BELEZA_VISUALIZAR',
                'CARACTERISTICAS_BELEZA_SALVAR',
                'CARACTERISTICAS_CALCADO_DELETAR',
                'CARACTERISTICAS_CALCADO_VISUALIZAR',
                'CARACTERISTICAS_CALCADO_SALVAR',
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_DELETAR',
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_VISUALIZAR',
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_SALVAR',
                'CARACTERISTICAS_COR-DA-PELE_DELETAR',
                'CARACTERISTICAS_COR-DA-PELE_VISUALIZAR',
                'CARACTERISTICAS_COR-DA-PELE_SALVAR',
                'CARACTERISTICAS_COR-DO-CABELO_DELETAR',
                'CARACTERISTICAS_COR-DO-CABELO_VISUALIZAR',
                'CARACTERISTICAS_COR-DO-CABELO_SALVAR',
                'CARACTERISTICAS_COR-DOS-OLHOS_DELETAR',
                'CARACTERISTICAS_COR-DOS-OLHOS_VISUALIZAR',
                'CARACTERISTICAS_COR-DOS-OLHOS_SALVAR',
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_DELETAR',
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_VISUALIZAR',
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_SALVAR',
                'CARACTERISTICAS_FORMACAO_DELETAR',
                'CARACTERISTICAS_FORMACAO_VISUALIZAR',
                'CARACTERISTICAS_FORMACAO_SALVAR',
                'CARACTERISTICAS_FUNCAO_DELETAR',
                'CARACTERISTICAS_FUNCAO_VISUALIZAR',
                'CARACTERISTICAS_FUNCAO_SALVAR',
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_DELETAR',
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_VISUALIZAR',
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_SALVAR',
                'CARACTERISTICAS_HABILIDADE_DELETAR',
                'CARACTERISTICAS_HABILIDADE_VISUALIZAR',
                'CARACTERISTICAS_HABILIDADE_SALVAR',
                'CARACTERISTICAS_IDIOMAS-FLUENTES_DELETAR',
                'CARACTERISTICAS_IDIOMAS-FLUENTES_VISUALIZAR',
                'CARACTERISTICAS_IDIOMAS-FLUENTES_SALVAR',
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_DELETAR',
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_VISUALIZAR',
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_SALVAR',
                'CARACTERISTICAS_MANEQUIM_DELETAR',
                'CARACTERISTICAS_MANEQUIM_VISUALIZAR',
                'CARACTERISTICAS_MANEQUIM_SALVAR',
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_DELETAR',
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_VISUALIZAR',
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_SALVAR',
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_DELETAR',
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_VISUALIZAR',
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_SALVAR',
                'CARACTERISTICAS_SOTAQUE-NATURAL_DELETAR',
                'CARACTERISTICAS_SOTAQUE-NATURAL_VISUALIZAR',
                'CARACTERISTICAS_SOTAQUE-NATURAL_SALVAR',
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_DELETAR',
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_VISUALIZAR',
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_SALVAR',
                'CARACTERISTICAS_TAMANHO-DO-CABELO_DELETAR',
                'CARACTERISTICAS_TAMANHO-DO-CABELO_VISUALIZAR',
                'CARACTERISTICAS_TAMANHO-DO-CABELO_SALVAR',
                'CARACTERISTICAS_TATUAGEM_DELETAR',
                'CARACTERISTICAS_TATUAGEM_VISUALIZAR',
                'CARACTERISTICAS_TATUAGEM_SALVAR',
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_DELETAR',
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_VISUALIZAR',
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_SALVAR',
                'CARACTERISTICAS_TIPO-DE-CABELO_DELETAR',
                'CARACTERISTICAS_TIPO-DE-CABELO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-DE-CABELO_SALVAR',
                'CARACTERISTICAS_TIPO-ETNICO_DELETAR',
                'CARACTERISTICAS_TIPO-ETNICO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-ETNICO_SALVAR',
                'CARACTERISTICAS_TIPO-FISICO_DELETAR',
                'CARACTERISTICAS_TIPO-FISICO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-FISICO_SALVAR',
                'CARACTERISTICAS_VEICULO_DELETAR',
                'CARACTERISTICAS_VEICULO_VISUALIZAR',
                'CARACTERISTICAS_VEICULO_SALVAR'
            ],
            artesMarciais: [
                'CARACTERISTICAS_ARTES-MARCIAIS_DELETAR',
                'CARACTERISTICAS_ARTES-MARCIAIS_VISUALIZAR',
                'CARACTERISTICAS_ARTES-MARCIAIS_SALVAR'
            ],
            beleza: [
                'CARACTERISTICAS_BELEZA_DELETAR',
                'CARACTERISTICAS_BELEZA_VISUALIZAR',
                'CARACTERISTICAS_BELEZA_SALVAR'
            ],
            calcado: [
                'CARACTERISTICAS_CALCADO_DELETAR',
                'CARACTERISTICAS_CALCADO_VISUALIZAR',
                'CARACTERISTICAS_CALCADO_SALVAR'
            ],
            caracteristicasEspeciais: [
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_DELETAR',
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_VISUALIZAR',
                'CARACTERISTICAS_CARACTERISTICAS-ESPECIAIS_SALVAR'
            ],
            corDaPele: [
                'CARACTERISTICAS_COR-DA-PELE_DELETAR',
                'CARACTERISTICAS_COR-DA-PELE_VISUALIZAR',
                'CARACTERISTICAS_COR-DA-PELE_SALVAR'
            ],
            corDoCabelo: [
                'CARACTERISTICAS_COR-DO-CABELO_DELETAR',
                'CARACTERISTICAS_COR-DO-CABELO_VISUALIZAR',
                'CARACTERISTICAS_COR-DO-CABELO_SALVAR'
            ],
            corDoOlho: [
                'CARACTERISTICAS_COR-DOS-OLHOS_DELETAR',
                'CARACTERISTICAS_COR-DOS-OLHOS_VISUALIZAR',
                'CARACTERISTICAS_COR-DOS-OLHOS_SALVAR'
            ],
            esporteQuePratica: [
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_DELETAR',
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_VISUALIZAR',
                'CARACTERISTICAS_ESPORTES-QUE-PRATICA_SALVAR'
            ],
            formacao: [
                'CARACTERISTICAS_FORMACAO_DELETAR',
                'CARACTERISTICAS_FORMACAO_VISUALIZAR',
                'CARACTERISTICAS_FORMACAO_SALVAR'
            ],
            funcao: [
                'CARACTERISTICAS_FUNCAO_DELETAR',
                'CARACTERISTICAS_FUNCAO_VISUALIZAR',
                'CARACTERISTICAS_FUNCAO_SALVAR'
            ],
            habilidadeDeCirco: [
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_DELETAR',
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_VISUALIZAR',
                'CARACTERISTICAS_HABILIDADE-DE-CIRCO_SALVAR'
            ],
            habilidade: [
                'CARACTERISTICAS_HABILIDADE_DELETAR',
                'CARACTERISTICAS_HABILIDADE_VISUALIZAR',
                'CARACTERISTICAS_HABILIDADE_SALVAR'
            ],
            idiomaFluente: [
                'CARACTERISTICAS_IDIOMAS-FLUENTES_DELETAR',
                'CARACTERISTICAS_IDIOMAS-FLUENTES_VISUALIZAR',
                'CARACTERISTICAS_IDIOMAS-FLUENTES_SALVAR'
            ],
            instrumentoMusical: [
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_DELETAR',
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_VISUALIZAR',
                'CARACTERISTICAS_INSTRUMENTOS-MUSICAIS_SALVAR'
            ],
            manequim: [
                'CARACTERISTICAS_MANEQUIM_DELETAR',
                'CARACTERISTICAS_MANEQUIM_VISUALIZAR',
                'CARACTERISTICAS_MANEQUIM_SALVAR'
            ],
            necessidadeEspecial: [
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_DELETAR',
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_VISUALIZAR',
                'CARACTERISTICAS_NECESSIDADES-ESPECIAIS_SALVAR'
            ],
            possuiCadastroEm: [
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_DELETAR',
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_VISUALIZAR',
                'CARACTERISTICAS_POSSUI-CADASTRO-EM_SALVAR'
            ],
            sotaqueNatural: [
                'CARACTERISTICAS_SOTAQUE-NATURAL_DELETAR',
                'CARACTERISTICAS_SOTAQUE-NATURAL_VISUALIZAR',
                'CARACTERISTICAS_SOTAQUE-NATURAL_SALVAR'
            ],
            sotaqueQueDomina: [
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_DELETAR',
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_VISUALIZAR',
                'CARACTERISTICAS_SOTAQUE-QUE-DOMINA_SALVAR'
            ],
            tamanhoDoCabelo: [
                'CARACTERISTICAS_TAMANHO-DO-CABELO_DELETAR',
                'CARACTERISTICAS_TAMANHO-DO-CABELO_VISUALIZAR',
                'CARACTERISTICAS_TAMANHO-DO-CABELO_SALVAR'
            ],
            tatuagem: [
                'CARACTERISTICAS_TATUAGEM_DELETAR',
                'CARACTERISTICAS_TATUAGEM_VISUALIZAR',
                'CARACTERISTICAS_TATUAGEM_SALVAR'
            ],
            tipoDeCabeleira: [
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_DELETAR',
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_VISUALIZAR',
                'CARACTERISTICAS_TIPO-DE-CABELEIRA_SALVAR'
            ],
            tipoDeCabelo: [
                'CARACTERISTICAS_TIPO-DE-CABELO_DELETAR',
                'CARACTERISTICAS_TIPO-DE-CABELO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-DE-CABELO_SALVAR'
            ],
            tipoEtnico: [
                'CARACTERISTICAS_TIPO-ETNICO_DELETAR',
                'CARACTERISTICAS_TIPO-ETNICO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-ETNICO_SALVAR'
            ],
            tipoFisico: [
                'CARACTERISTICAS_TIPO-FISICO_DELETAR',
                'CARACTERISTICAS_TIPO-FISICO_VISUALIZAR',
                'CARACTERISTICAS_TIPO-FISICO_SALVAR'
            ],
            veiculo: [
                'CARACTERISTICAS_VEICULO_DELETAR',
                'CARACTERISTICAS_VEICULO_VISUALIZAR',
                'CARACTERISTICAS_VEICULO_SALVAR'
            ]
        }
    }
};
