import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: menuRoles.itensMenu.caracteristicas.roles,
            itens: [
                {
                    label: 'Home',
                    icone: 'home',
                    link: '/home',
                    roles: menuRoles.itensMenu.caracteristicas.roles
                }
            ]
        },
        {
            label: 'Cadastro de Atores',
            roles: menuRoles.itensMenu.cadastroDeAtores.roles,
            itens: [
                {
                    label: 'Atores',
                    icone: 'assignment_ind',
                    link: '/cadastro-de-atores/atores',
                    roles: menuRoles.itensMenu.cadastroDeAtores.atores
                }
            ]
        },
        {
            label: 'Características',
            roles: menuRoles.itensMenu.caracteristicas.roles,
            itens: [
                {
                    label: 'Artes Marciais',
                    icone: 'sports_mma',
                    link: '/caracteristicas/artes-marciais',
                    roles: menuRoles.itensMenu.caracteristicas.artesMarciais
                },
                {
                    label: 'Beleza',
                    icone: 'face',
                    link: '/caracteristicas/beleza',
                    roles: menuRoles.itensMenu.caracteristicas.beleza
                },
                {
                    label: 'Calçado',
                    icone: 'directions_walk',
                    link: '/caracteristicas/calcado',
                    roles: menuRoles.itensMenu.caracteristicas.calcado
                },
                {
                    label: 'Características Especiais',
                    icone: 'account_circle',
                    link: '/caracteristicas/caracteristicas-especiais',
                    roles: menuRoles.itensMenu.caracteristicas.caracteristicasEspeciais
                },
                {
                    label: 'Cor da Pele',
                    icone: 'palette',
                    link: '/caracteristicas/cor-da-pele',
                    roles: menuRoles.itensMenu.caracteristicas.corDaPele
                },
                {
                    label: 'Cor do Cabelo',
                    icone: 'palette',
                    link: '/caracteristicas/cor-do-cabelo',
                    roles: menuRoles.itensMenu.caracteristicas.corDoCabelo
                },
                {
                    label: 'Cor do Olho',
                    icone: 'palette',
                    link: '/caracteristicas/cor-do-olho',
                    roles: menuRoles.itensMenu.caracteristicas.corDoOlho
                },
                {
                    label: 'Esportes que Pratica',
                    icone: 'sports',
                    link: '/caracteristicas/esporte-que-pratica',
                    roles: menuRoles.itensMenu.caracteristicas.esporteQuePratica
                },
                {
                    label: 'Formação',
                    icone: 'school',
                    link: '/caracteristicas/formacao',
                    roles: menuRoles.itensMenu.caracteristicas.formacao
                },
                {
                    label: 'Função',
                    icone: 'theaters',
                    link: '/caracteristicas/funcao',
                    roles: menuRoles.itensMenu.caracteristicas.funcao
                },
                {
                    label: 'Habilidade',
                    icone: 'brush',
                    link: '/caracteristicas/habilidade',
                    roles: menuRoles.itensMenu.caracteristicas.habilidade
                },
                {
                    label: 'Habilidade de Circo',
                    icone: 'festival',
                    link: '/caracteristicas/habilidade-de-circo',
                    roles: menuRoles.itensMenu.caracteristicas.habilidadeDeCirco
                },
                {
                    label: 'Idioma Fluente',
                    icone: 'language',
                    link: '/caracteristicas/idioma-fluente',
                    roles: menuRoles.itensMenu.caracteristicas.idiomaFluente
                },
                {
                    label: 'Instrumento Musical',
                    icone: 'music_note',
                    link: '/caracteristicas/instrumento-musical',
                    roles: menuRoles.itensMenu.caracteristicas.instrumentoMusical
                },
                {
                    label: 'Manequim',
                    icone: 'girl',
                    link: '/caracteristicas/manequim',
                    roles: menuRoles.itensMenu.caracteristicas.manequim
                },
                {
                    label: 'Necessidade Especial',
                    icone: 'accessible',
                    link: '/caracteristicas/necessidade-especial',
                    roles: menuRoles.itensMenu.caracteristicas.necessidadeEspecial
                },
                {
                    label: 'Possui Cadastro Em',
                    icone: 'inventory',
                    link: '/caracteristicas/possui-cadastro-em',
                    roles: menuRoles.itensMenu.caracteristicas.possuiCadastroEm
                },
                {
                    label: 'Sotaque Natural',
                    icone: 'voicemail',
                    link: '/caracteristicas/sotaque-natural',
                    roles: menuRoles.itensMenu.caracteristicas.sotaqueNatural
                },
                {
                    label: 'Sotaque que Domina',
                    icone: 'record_voice_over',
                    link: '/caracteristicas/sotaque-que-domina',
                    roles: menuRoles.itensMenu.caracteristicas.sotaqueQueDomina
                },
                {
                    label: 'Tamanho do Cabelo',
                    icone: 'content_cut',
                    link: '/caracteristicas/tamanho-do-cabelo',
                    roles: menuRoles.itensMenu.caracteristicas.tamanhoDoCabelo
                },
                {
                    label: 'Tatuagens',
                    icone: 'bolt',
                    link: '/caracteristicas/tatuagens',
                    roles: menuRoles.itensMenu.caracteristicas.tatuagem
                },
                {
                    label: 'Tipo de Cabeleira',
                    icone: 'content_cut',
                    link: '/caracteristicas/tipo-de-cabeleira',
                    roles: menuRoles.itensMenu.caracteristicas.tipoDeCabeleira
                },
                {
                    label: 'Tipo de Cabelo',
                    icone: 'content_cut',
                    link: '/caracteristicas/tipo-de-cabelo',
                    roles: menuRoles.itensMenu.caracteristicas.tipoDeCabelo
                },
                {
                    label: 'Tipo Étnico',
                    icone: 'face_4',
                    link: '/caracteristicas/tipo-etnico',
                    roles: menuRoles.itensMenu.caracteristicas.tipoEtnico
                },
                {
                    label: 'Tipo Físico',
                    icone: 'accessibility',
                    link: '/caracteristicas/tipo-fisico',
                    roles: menuRoles.itensMenu.caracteristicas.tipoFisico
                },
                {
                    label: 'Veículo',
                    icone: 'directions_car',
                    link: '/caracteristicas/veiculo',
                    roles: menuRoles.itensMenu.caracteristicas.veiculo
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        _permissoesService.initPermissoes();
        // console.log(_localStorage.getItem('appRoles'));
    }

    ngOnInit() {}

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }
}
