import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'cadastro-de-atores/atores',
        loadChildren: () =>
            import('./pages/cadastro-de-atores/atores/atores.module').then((m) => m.AtoresModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.cadastroDeAtores.atores }
    },
    {
        path: 'caracteristicas/artes-marciais',
        loadChildren: () =>
            import('./pages/caracteristicas/artes-marciais/artes-marciais.module').then(
                (m) => m.ArtesMarciaisModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.artesMarciais }
    },
    {
        path: 'caracteristicas/beleza',
        loadChildren: () =>
            import('./pages/caracteristicas/beleza/beleza.module').then((m) => m.BelezaModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.beleza }
    },
    {
        path: 'caracteristicas/calcado',
        loadChildren: () =>
            import('./pages/caracteristicas/calcado/calcado.module').then((m) => m.CalcadoModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.calcado }
    },
    {
        path: 'caracteristicas/caracteristicas-especiais',
        loadChildren: () =>
            import(
                './pages/caracteristicas/caracteristicas-especiais/caracteristicas-especiais.module'
            ).then((m) => m.CaracteristicasEspeciaisModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.caracteristicasEspeciais }
    },
    {
        path: 'caracteristicas/cor-da-pele',
        loadChildren: () =>
            import('./pages/caracteristicas/cor-da-pele/cor-da-pele.module').then(
                (m) => m.CorDaPeleModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.corDaPele }
    },
    {
        path: 'caracteristicas/cor-do-cabelo',
        loadChildren: () =>
            import('./pages/caracteristicas/cor-do-cabelo/cor-do-cabelo.module').then(
                (m) => m.CorDoCabeloModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.corDoCabelo }
    },
    {
        path: 'caracteristicas/cor-do-olho',
        loadChildren: () =>
            import('./pages/caracteristicas/cor-do-olho/cor-do-olho.module').then(
                (m) => m.CorDoOlhoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.corDoOlho }
    },
    {
        path: 'caracteristicas/esporte-que-pratica',
        loadChildren: () =>
            import('./pages/caracteristicas/esporte-que-pratica/esporte-que-pratica.module').then(
                (m) => m.EsporteQuePraticaModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.esporteQuePratica }
    },
    {
        path: 'caracteristicas/formacao',
        loadChildren: () =>
            import('./pages/caracteristicas/formacao/formacao.module').then(
                (m) => m.FormacaoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.formacao }
    },
    {
        path: 'caracteristicas/funcao',
        loadChildren: () =>
            import('./pages/caracteristicas/funcao/funcao.module').then((m) => m.FuncaoModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.funcao }
    },
    {
        path: 'caracteristicas/habilidade',
        loadChildren: () =>
            import('./pages/caracteristicas/habilidade/habilidade.module').then(
                (m) => m.HabilidadeModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.habilidade }
    },
    {
        path: 'caracteristicas/habilidade-de-circo',
        loadChildren: () =>
            import('./pages/caracteristicas/habilidade-de-circo/habilidade-de-circo.module').then(
                (m) => m.HabilidadeDeCircoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.habilidadeDeCirco }
    },
    {
        path: 'caracteristicas/idioma-fluente',
        loadChildren: () =>
            import('./pages/caracteristicas/idioma-fluente/idioma-fluente.module').then(
                (m) => m.IdiomaFluenteModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.idiomaFluente }
    },
    {
        path: 'caracteristicas/instrumento-musical',
        loadChildren: () =>
            import('./pages/caracteristicas/instrumento-musical/instrumento-musical.module').then(
                (m) => m.InstrumentoMusicalModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.instrumentoMusical }
    },
    {
        path: 'caracteristicas/manequim',
        loadChildren: () =>
            import('./pages/caracteristicas/manequim/manequim.module').then(
                (m) => m.ManequimModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.manequim }
    },
    {
        path: 'caracteristicas/necessidade-especial',
        loadChildren: () =>
            import('./pages/caracteristicas/necessidade-especial/necessidade-especial.module').then(
                (m) => m.NecessidadeEspecialModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.necessidadeEspecial }
    },
    {
        path: 'caracteristicas/possui-cadastro-em',
        loadChildren: () =>
            import('./pages/caracteristicas/possui-cadastro-em/possui-cadastro-em.module').then(
                (m) => m.PossuiCadastroEmModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.possuiCadastroEm }
    },
    {
        path: 'caracteristicas/sotaque-natural',
        loadChildren: () =>
            import('./pages/caracteristicas/sotaque-natural/sotaque-natural.module').then(
                (m) => m.SotaqueNaturalModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.sotaqueNatural }
    },
    {
        path: 'caracteristicas/sotaque-que-domina',
        loadChildren: () =>
            import('./pages/caracteristicas/sotaque-que-domina/sotaque-que-domina.module').then(
                (m) => m.SotaqueQueDominaModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.sotaqueQueDomina }
    },
    {
        path: 'caracteristicas/tamanho-do-cabelo',
        loadChildren: () =>
            import('./pages/caracteristicas/tamanho-do-cabelo/tamanho-do-cabelo.module').then(
                (m) => m.TamanhoDoCabeloModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tamanhoDoCabelo }
    },
    {
        path: 'caracteristicas/tatuagens',
        loadChildren: () =>
            import('./pages/caracteristicas/tatuagens/tatuagens.module').then(
                (m) => m.TatuagensModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tatuagem }
    },
    {
        path: 'caracteristicas/tipo-de-cabeleira',
        loadChildren: () =>
            import('./pages/caracteristicas/tipo-de-cabeleira/tipo-de-cabeleira.module').then(
                (m) => m.TipoDeCabeleiraModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tipoDeCabeleira }
    },
    {
        path: 'caracteristicas/tipo-de-cabelo',
        loadChildren: () =>
            import('./pages/caracteristicas/tipo-de-cabelo/tipo-de-cabelo.module').then(
                (m) => m.TipoDeCabeloModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tipoDeCabelo }
    },
    {
        path: 'caracteristicas/tipo-etnico',
        loadChildren: () =>
            import('./pages/caracteristicas/tipo-etnico/tipo-etnico.module').then(
                (m) => m.TipoEtnicoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tipoEtnico }
    },
    {
        path: 'caracteristicas/tipo-fisico',
        loadChildren: () =>
            import('./pages/caracteristicas/tipo-fisico/tipo-fisico.module').then(
                (m) => m.TipoFisicoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.tipoFisico }
    },
    {
        path: 'caracteristicas/veiculo',
        loadChildren: () =>
            import('./pages/caracteristicas/veiculo/veiculo.module').then((m) => m.VeiculoModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.caracteristicas.veiculo }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
